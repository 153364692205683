<template>
	<div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div style="overflow: hidden;">
              <h3 class="float-left">News Letter list</h3>
            </div>
            <hr>
						<DataTable :data="dataList" :columns="columnss" class="table table-striped">
							<thead>
								<tr>
									<th>SL</th>
									<th>Name</th>
									<th>Email</th>
									<th>Action</th>
								</tr>
							</thead>
						<template #column-3="props">
						<Button class="btn btn-danger btn-sm"
						@click="remove(props.rowData)"
						><i class="fa fa-trash"></i></Button>
						</template>
						</DataTable>
						<div class="loader" v-if="$store.state.loadding"></div>
            </div>
          </div>
        </div>
  </div>

</template>

<script>

import { mapState } from 'vuex';

import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import  'datatables.net-bs4';
DataTable.use(DataTablesCore);


export default {
  data() {
			return {
				columnss : [
						{data : null, render : function(data, type, row, meta){ return `${meta.row + 1}`}},
						{data : "name"},
						{data : "email"}
				]
			}
  },
	computed : {
		...mapState('newsLetter', {
			dataList : state => state.dataList
		})
	},
  methods: {
		addMore(){
			this.insertData.push({name : ''});
		},
		remove(data) {
			if(confirm("Are you sure delete?")){
				this.$store.dispatch("newsLetter/remove", data);
			}
		}
  },
	mounted(){
		this.$store.dispatch("newsLetter/getData");
	},
	components : {
		DataTable
	},
	created(){
    document.title = "News letter"
  }
};
</script>