<template>
  <button 
    class="btn" 
    :class="{
      'btn-block': block,
      'btn-primary': color === 'none',
      'btn-dark': color === 'dark',
    }"
    :disabled="disabled"
    >{{text}}<img class="loadding" v-if="loading" src="/img/spinner.gif"/></button>
</template>

<script>
export default {
  name: 'TheButton',
  data(){
    return {
      myButton : false
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled : {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "none"
    },
    text: {
      type: String,
      default: "Login"
    }
  }
};
</script>

<style lang="css" scoped>
  .loadding{
    height: 20px;
    margin-left: 5px
  }
</style>