<template>
    <SkeletionNavbar v-if="loading"></SkeletionNavbar>

    <nav v-else class="navbar bg-white navbar-expand-md sticky-top">
      <div class="container">

        <router-link to="/" class="navbar-brand">
          <img :src="$store?.state?.siteData.logo" alt="Site Image" style="height:40px">
        </router-link>

        <button data-target="#myNav" data-toggle="collapse" class="navbar-toggler navbar-toggler-right">
          <i class="fa fa-bars" style="font-size: 25px;" aria-hidden="true"></i>
        </button>

        <div class="navbar-collapse collapse" id="myNav">
          <ul class="navbar-nav ml-auto">
            <li 
              class="nav-item dropdown"
              v-for="(navlist) in navLists"
              :key="navlist.name"
            >
                <span v-if="navlist.children != ''">
                  <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{navlist.name}}</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                    <span v-for="child in navlist.children" :key="child.name">
                      <a :href="child.link" target="_blank" v-if="child.link.startsWith('http')" class="nav-link">{{navlist.name}}</a>
                      <router-link v-else :to="child.link" class="nav-link">{{child.name}}</router-link>
                    </span>
                  </div>
                </span>
                <span v-else>
                  <a :href="navlist.link" target="_blank" v-if="navlist.link.startsWith('http')" class="nav-link">{{navlist.name}}</a>
                  <router-link v-else :to="navlist.link" class="nav-link">{{navlist.name}}</router-link>
                </span>
            </li>
          </ul>
        </div>
      </div>
    </nav>

</template>

<script>
import SkeletionNavbar from "../SkeletionNavbar.vue";
import myAxios from "../../../service/axios.js";
export default {

  name: 'HeaderPage',
  data () {
    return {
      navLists : [],
      loading : true
    }
  },
  mounted(){
    this.getData();
  },
  methods : {
    async getData(){
      this.loading = true;
      try{
        await myAxios
        .get("getNavbar")
        .then((response) => {
          this.loading = false;
          this.navLists = response.data.data
        })
      }catch(err){
        this.loading = true;
      }
    }
  },
  components : {
    SkeletionNavbar
  }
}
</script>

<style lang="css" scoped>
  nav a {
    font-size: 16px;
  }
  @media (min-width: 768px) {
    .dropdown-menu{
      width : 340px;
    }

}
</style>