<template>
	<div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div style="overflow: hidden;">
              <h3 class="float-left">Message List</h3>
            </div>
            <hr>
						<DataTable :data="dataList" :columns="columnss" class="table table-striped">
							<thead>
								<tr>
									<th>SL</th>
									<th>Name</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Action</th>
								</tr>
							</thead>
						<template #column-4="props">
						<Button class="btn btn-primary mr-2 btn-sm"
						@click="viewData(props.rowData)"
						><i class="fa fa-eye"></i></Button>
						<Button class="btn btn-danger btn-sm"
						@click="remove(props.rowData)"
						><i class="fa fa-trash"></i></Button>
						</template>
						</DataTable>
						<div class="loader" v-if="$store.state.loadding"></div>
            </div>
          </div>
        </div>
  </div>

<!-- View modal -->
<div class="modal fade" id="viewModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Message</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">

					<table class="table table-striped table-responsive-sm table-bordered">
						<tr>
							<th>Name: </th>
							<td>{{selectMessage.firstName}} {{selectMessage.lastName}}</td>
						</tr>

						<tr>
							<th>Email: </th>
							<td>{{selectMessage.email}}</td>
						</tr>

						<tr>
							<th>Phone: </th>
							<td>{{selectMessage.phone}}</td>
						</tr>

						<tr>
							<th>Message: </th>
							<td style="white-space: break-spaces;">{{selectMessage.message}}</td>
						</tr>

					</table>
			</div>
		</div>
	</div>
</div>

</template>

<script>

import { mapState } from 'vuex';

import { onMounted } from 'vue';
import $ from 'jquery';
import 'bootstrap';

import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import  'datatables.net-bs4';
DataTable.use(DataTablesCore);

let viewModal = null;

export default {
	setup(){
		onMounted(()=>{
			viewModal = $("#viewModal");
		})
	},
  data() {
			return {
				columnss : [
						{data : null, render : function(data, type, row, meta){ return `${meta.row + 1}`}},
						{data : null, render : function(data){ return `${data.firstName +" "+ data.lastName}`}},
						{data : "email"},
						{data : "phone"}
				],
				selectMessage : []
			}
  },
	computed : {
		...mapState('feedback', {
			dataList : state => state.dataList
		})
	},
  methods: {
		viewData(data){
			viewModal.modal("show");
			this.selectMessage = data;
		},
		remove(data) {
			if(confirm("Are you sure delete?")){
				this.$store.dispatch("feedback/remove", data);
			}
		}
  },
	mounted(){
		this.$store.dispatch("feedback/getData");
	},
	components : {
		DataTable
	},
	created(){
    document.title = "Feedback"
  }
};
</script>