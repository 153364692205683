<template>
<div id="slider">
  <div class="carousel slide" id="myCarousel" data-ride="carousel">
    <loadingSection v-if="isLoading"></loadingSection>
    <div>
       <ol class="carousel-indicators">
        <li          
          v-for="(slider, id) in sliders" 
          :key="slider.title"  
          data-target="#myCarousel"
          :data-slide-to="id" 
          :class="{'active' : id == 0}"
        ></li>
        </ol>


        <div class="carousel-inner" role="listbox">
        <div 
          class="carousel-item slider" 
          v-for="(slider, id) in sliders" 
          :key="slider.title" 
          :style="{background: 'url('+ slider.image +')'}"
          :class="{active : id == 0}"
        >
        <div class="img-ovarlay">
          <div 
            class="carousel-caption mb-md-5 pb-md-5"
            :class="slider.align"
          >
            <h2 class="display-4 mb-4">{{slider.title}}</h2>
          <a
          v-if="slider.btnlink.startsWith('http')"
          :href="slider.btnlink"
          class="btn"
          :class="slider.btnColor"
          >Learn More</a>
          <router-link v-else 
            :to="slider.btnlink" 
            class="btn"
            :class="slider.btnColor"
            style="border-radius: 20px !important;"
          >Learn More</router-link>


          </div>
        </div>
        </div>
        </div>
        <a href="#myCarousel" data-slide="prev" class="carousel-control-prev"><span class="carousel-control-prev-icon"></span></a>
        <a href="#myCarousel" data-slide="next" class="carousel-control-next"><span class="carousel-control-next-icon"></span></a>
    </div>
  </div>
</div>

</template>

<script>
import { onMounted } from 'vue';
import myAxios from "../../../service/axios.js";
import loadingSection from "../SkeletionPageLarge.vue";
import $ from 'jquery';
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap';

let myCarousel = null;

export default {

  name: 'SliderPage',
  setup(){
    onMounted(()=>{
      myCarousel = $("#myCarousel");
    })
  },
  data () {
    return {
      sliders : [],
      isLoading : false
    }
  },
  mounted(){
    this.getData();
    myCarousel.carousel({
      interval: 3000,
      cycle: true
    });
  },
  methods : {
    async getData(){
      this.isLoading = true;
      try{
        await myAxios
        .get("getSlider")
        .then((response) => {
          this.isLoading = false;
          this.sliders = response.data.data
        })
      }catch(err){
        this.isLoading = true;
      }
    }
  },
  components : {
    loadingSection
  }
}
</script>

<style lang="css" scoped>
</style>