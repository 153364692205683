<template>
	<div class="col-md-10">
		<form class="py-4" @submit.prevent="update" autocomplete="off">
            <hr>
            <h6 class="text-center text-danger" id="msg"></h6>

            <div class="form-group">
              <label for="phone">Name</label>
              <input type="text" v-model="myData.name" class="form-control" placeholder="Name">
            </div>

            <div class="form-group">
              <label for="phone">Email</label>
              <input type="text" v-model="myData.email" class="form-control" placeholder="E-mail address">
            </div>
            <div class="form-group">
              <label for="phone">Avatar</label>
              <input type="file" @change="addFile">
            </div>
            <input type="submit" value="Update" class="btn btn-dark mt-4 btn-sm btn-block">
          </form>
	</div>
</template>

<script>
export default {

  name: 'UserlistPage',

  data () {
    return {
      myData : {
        name: this.$store.state.users.name,
        email: this.$store.state.users.email,
        avatar : []
      }
    }
  },
  methods : {
      addFile(e){
        this.myData.avatar = e.target.files[0];
      },

      async update(){

        // console.log(this.myData);

        try{
          this.$store.commit("loadding", true);

          // this.axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem('accessToken');

          await this.axios
          .get("userupdate", {
            headers : {
              'Content-Type' : 'multipart/form-data',
              'Authorization' : "Bearer "+localStorage.getItem('accessToken')
            },
            params : {
              product : 'Test'
            }
          })
          .then((response) => {
            this.$store.commit("loadding", false);

              console.log(response);


          })
        }catch(err){
          this.$store.commit("loadding", false);
          console.log("Response: ", err);
        }
    }
  },
  created(){
    document.title = "User List"
  }
}
</script>

<style lang="css" scoped>
</style>