<template>
  <div class="main-card">
    <div class="main-info">
      <span></span>
      <h3></h3>
      <p></p>
    </div>
  </div>
</template>
<style scoped>
.main-image img {
  width: 100%;
  padding-top: 100%;
  animation: pulse-bg 1s infinite;
}

.main-info span {
  width: 100%;
  height: 16px;
  display: block;
  animation: pulse-bg 1s infinite;
}

.main-info h3 {
  width: 250px;
  height: 24px;
  animation: pulse-bg 1s infinite;
}

.main-info p {
  width: 80%;
  height: 16px;
  animation: pulse-bg 1s infinite;
}

@keyframes pulse-bg {
  0% {
    background-color: #eee;
  }
  50% {
    background-color: #eaeaea;
  }
  100% {
    background-color: #eee;
  }
}
</style>