<template>
	<HeaderPage></HeaderPage>
	<router-view :key="$route.fullPath"></router-view>
	<FooterPage></FooterPage>
</template>

<script>
import HeaderPage from "./HeaderPage.vue";
import FooterPage from "./FooterPage.vue";
export default {

	name: 'LayoutPage',
	data () {
		return {

		}
	},
	components : {
		HeaderPage,
		FooterPage,
	}
}
</script>

<style lang="css" scoped>
</style>