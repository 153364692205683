<template>
	<div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div style="overflow: hidden;">
              <h3 class="float-left">Post List</h3>
							<h3 class="float-right">
								<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#insertModal">Add New</button>
							</h3>
            </div>
            <hr>
            <div class="loader" v-if="$store.state.loadding"></div>
						<DataTable v-else :data="dataList" :columns="columnss" class="table table-striped">
							<thead>
								<tr>
									<th>SL</th>
									<th>Image</th>
									<th>Title</th>
									<th>Write</th>
									<th>Status</th>
									<th>Action</th>
								</tr>
							</thead>
						<template #column-5="props">
						<Button class="btn btn-primary btn-sm mr-2"
						@click="edit(props.rowData)"
						><i class="fa fa-edit"></i></Button>
						<Button class="btn btn-danger btn-sm"
						@click="remove(props.rowData)"
						><i class="fa fa-trash"></i></Button>
						</template>
						</DataTable>
            </div>
          </div>
        </div>
  </div>

<!-- Insert modal -->
<div class="modal fade" id="insertModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Data</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
      <form enctype="multipart/form-data" @submit.prevent="insert">
				
				<p class="text-center text-danger" v-if="$store?.state?.errors?.network_error">{{$store?.state?.errors?.network_error}}</p>

				<div class="row" v-for="(item, id) in insertData" :key="id">
          <div class="col-md-12 my-2">
            <input type="text" class="form-control" v-model="item.title" placeholder="Title" required>
          </div>
          <div class="col-md-12 my-2">
            <input type="text" class="form-control" v-model="item.written" placeholder="Written" required>
          </div>
          <div class="col-md-12 my-2">
						<ckeditor :editor="editor" v-model="item.description" @ready="onReady"></ckeditor>
          </div>

          <div class="col-md-12 my-2">
            <input type="file" class="form-control-file" id="file" @change="image($event, 0)" required>
          </div>
        </div>
				<TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
      </form>
      </div>
    </div>
  </div>
</div>
 

<!-- Update modal -->
<div class="modal fade" id="updateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
	<div class="modal-dialog modal-lg" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Update Data</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">

			<form enctype="multipart/form-data" @submit.prevent="update">
				<p class="text-center text-danger" v-if="$store?.state?.errors?.network_error">{{$store.state.errors.network_error}}</p>

					<div class="col-md-12 my-2">
            <input type="text" class="form-control" v-model="updateData.title" placeholder="Enter name" required>
          </div>

          <div class="col-md-12 my-2">
            <input type="text" class="form-control" v-model="updateData.written" placeholder="Written" required>
          </div>

          <div class="col-md-12 my-2">

						<ckeditor :editor="editor" @ready="onReady" v-model="updateData.description"></ckeditor>
          </div>

          <div class="col-md-12 my-2">
            <input type="file" class="form-control-file" @change="imageUp">
          </div>

          <div class="col-md-12 my-2">
            <select name="status" class="form-control" v-model="updateData.status">
							<option value="1">Active</option>
							<option value="0">Inactive</option>
						</select>
          </div>
				<TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
			</form>
			</div>
		</div>
	</div>
</div>

</template>

<script>

import { onMounted } from 'vue';
import { mapState } from 'vuex';
import $ from 'jquery';
import 'bootstrap';

import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import TheButton from "../../helper/TheButton.vue";
import  'datatables.net-bs4';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';


DataTable.use(DataTablesCore);


let updateModal = null;
let insertModal = null;

export default {
	setup(){
		onMounted(()=>{
			updateModal = $("#updateModal");
			insertModal = $("#insertModal");
		})
	},
  data() {
			return {
				columnss : [
						{data : null, render : function(data, type, row, meta){ return `${meta.row + 1}`}},
						{data : null, render : function(data){ 
							return `<img src="${data.image}" style="height: 60px" alt="image"/>`}
						},
						{data : "title"},
						{data : "written"},
						{data : null, render : function(data){
							if(data.status == 1){
								return `<span class="badge bg-success">Active</span>`;
							}else{
								return `<span class="badge bg-danger p-2">Inactive</span>`;
							}
						}},
				],
				insertData : [{title : '', written : '', description : '', image : ''}],
				updateData : {id : '', title : '', written : '', description : '', image : '', status : ''},
				editor: DecoupledEditor
			}
  },
	computed : {
		...mapState('post', {
			dataList : state => state.dataList
		})
	},
  methods: {
		onReady( editor )  {
					editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element,editor.ui.getEditableElement());
		},
		image(event, index){
			this.insertData[index].image = event.target.files[0] ? event.target.files[0] : "";
		},		
		imageUp(){
			this.updateData.image = event.target.files[0] ? event.target.files[0] : "";
		},
		insert(){
			this.$store.dispatch("post/insert", {data : this.insertData, myModal : insertModal});
			this.insertData = [{title : '', written : '', description : '', image : ''}];
			document.getElementById("file").value = "";
		},
		edit(data){
			updateModal.modal("show");
			this.updateData.id 							= data.id;
			this.updateData.title 					= data.title;
			this.updateData.written 				= data.written;
			this.updateData.description 		= data.description;
			this.updateData.status 					= data.status;
		},
		update() {
			this.$store.dispatch("post/update", {data : this.updateData, myModal : updateModal});
		},
		remove(data) {
			if(confirm("Are you sure delete?")){
				this.$store.dispatch("post/remove", data);
			}
		}
  },
	mounted(){
		this.$store.dispatch("post/getData");
	},
	components : {
		DataTable,
		TheButton
	},
	created(){
    document.title = "Post list";
  }
};
</script>

<style lang="css" scoped>
	.ck-editor__editable {
		min-height: 300px;
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}

</style>
