import myAxios from "../../service/axios.js";
export default {
	namespaced : true,
	state(){
		return {
			dataList : []
		}
	},
	getters : {},
	mutations : {},
	actions : {
		async getData(context){
			try{
				context.commit('loadding', true, {root: true});
				await myAxios
				.get("getCardSection", {
					headers : {
						"Authorization" : "Bearer "+localStorage.getItem('accessToken')
					}
				})
				.then((response) => {
					context.state.dataList = response?.data?.data;
					context.commit('loadding', false, {root: true});
				})
			}catch(err){
				context.commit('loadding', false, {root: true});
				if(err.code == "ERR_NETWORK"){
					context.commit('errors', {network_error : err.message}, {root: true});
				}else{
					context.commit('errors', err.response.data.errors, {root: true});
				}
				if(err?.response?.data.message == "Unauthenticated."){
					context.commit("autologout",{}, {root: true});
				}
			}
		},
		async insert(context, payload){
			try{
				context.commit('loadding', true, {root: true});
				await myAxios
				.post("admin/insertCardSection", payload.data, {
					headers : {
						"Authorization" : "Bearer "+localStorage.getItem('accessToken'),
						"Content-Type" : "multipart/form-data"
					}
				})
				.then(() => {
					context.commit('loadding', false, {root: true});
					context.dispatch('getData');
					payload.myModal.modal("hide");
				})
			}catch(err){
				context.commit('loadding', false, {root: true});
				if(err.code == "ERR_NETWORK"){
					context.commit('errors', {network_error : err.message}, {root: true});
				}else{
					context.commit('errors', err.response.data.data, {root: true});
				}
				if(err.response.data.message == "Unauthenticated."){
					context.commit("autologout",{}, {root: true});
				}
			}
		},
		async update(context, payload){
			try{
				context.commit('loadding', true, {root: true});
				await myAxios
				.post("admin/updateCardSection", payload.data, {
					headers : {
						"Authorization" : "Bearer "+localStorage.getItem('accessToken')
					}
				})
				.then(() => {
					context.commit('loadding', false, {root: true});
					context.dispatch('getData');
					payload.myModal.modal("hide");
				})
			}catch(err){
				context.commit('loadding', false, {root: true});
				if(err.code == "ERR_NETWORK"){
					context.commit('errors', {network_error : err.message}, {root: true});
				}else{
					context.commit('errors', err.response.data.data, {root: true});
				}
				if(err.response.data.message == "Unauthenticated."){
					context.commit("autologout",{}, {root: true});
				}
			}
		},
		async remove(context, payload){
			try{
				await myAxios
				.delete("admin/deleteCardSection", {
					params : {
						id : payload.id
					},
					headers : {
						"Authorization" : "Bearer "+localStorage.getItem('accessToken')
					}
				})
				.then(() => {
					context.dispatch('getData');
				})
			}catch(err){
				context.commit('loadding', false, {root: true});
				if(err.code == "ERR_NETWORK"){
					context.commit('errors', {network_error : err.message}, {root: true});
				}else{
					context.commit('errors', err.response.data.errors, {root: true});
				}
				if(err.response.data.message == "Unauthenticated."){
					context.commit("autologout",{}, {root: true});
				}
			}
		}
	}
}