<template>
  <HeaderPage></HeaderPage>
  <section class="container-fluid">
    <div class="row">
      <SideBarPage></SideBarPage>
      <router-view></router-view>
    </div>
  </section>
  <FooterPage></FooterPage>
</template>

<script>

import HeaderPage from "./HeaderPage.vue";
import FooterPage from "./FooterPage.vue";
import SideBarPage from "./SideBarPage.vue";

export default {
  name: 'LayoutPage',
  data () {
    return {

    }
  },
  components : {
    HeaderPage,
    FooterPage,
    SideBarPage
	},
  created(){
    // alert(window.innerHeight)
  }
}
</script>