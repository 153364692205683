<template>
	<section id="contact-us" class="py-5" style="background : rgb(8 44 124); color: white;">
			<div class="container">
				<img :src="details.image" class="card-img-top" style="max-height: 400px;">
				<h4 class="my-4">{{details.title}}</h4>
				<div v-html="details.description"></div>
			</div>
    </section>
</template>

<script>

import myAxios from "../../service/axios.js";

export default {

  name: 'PostDetails',
	data () {
		return {
			details : [],
			loading : false
		}
	},
	mounted(){
		this.getCardData();
	},
	methods : {
			async getCardData(){
				this.loading = true;
				try{
					await myAxios
					.get("getPost")
					.then((response) => {
						let data = response.data.data.find((data) => {
							if(data.slug == this.$route.params.slug){
								return data.slug = this.$route.params.slug
							}
							return false;
						});
						this.details = data;
						document.title = data.title;
						this.loading = false;
					})
				}catch(err){
					this.loading = true;
					//err
				}
			}
	}
}
</script>

<style lang="css" scoped>
</style>