<template>
  <div class="col-md-10">
    <h4 class="my-4">Data Update</h4>
    <hr>
    <div class="row">

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12 my-2">
            <div class="card">
              <div class="card-body">
                <h4 class="mb-4">Top Banner</h4>
                <form class="my-2" @submit.prevent="topBanner_update">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="topBanner.value.title" placeholder="Title" required>
                    </div>
                    <div class="form-group">
                      <input type="text" class="form-control" placeholder="Link" v-model="topBanner.value.link">
                    </div>
                    <div class="form-group">
                      <textarea class="form-control" rows="5" required placeholder="Description" v-model="topBanner.value.description"></textarea>
                    </div>

                    <div class="form-group">
                      <input type="file" class="form-control-file" id="topBanner" @change="image($event, topBanner)">
                    </div>
                    <div class="form-group">
                      <button class="btn btn-primary">Save Change</button>
                    </div>
                  </div>
                </div>
                </form>
              </div>
            </div></div> 
          <div class="col-md-12 my-2">
              <div class="card">
                <div class="card-body">
                  <h4 class="mb-4">Crash Course</h4>
                  <form class="my-2" @submit.prevent="crashcourse">
                  <div class="row">
                    <div class="col-md-12">

                      <div class="form-group">
                        <input type="text" class="form-control" v-model="crash_course.value.link" placeholder="Link" required>
                      </div>
                      <div class="form-group">
                        <button class="btn btn-primary">Save Change</button>
                      </div>
                    </div>
                  </div>
                  </form>
                </div>
              </div></div>
          <div class="col-md-12 my-2">
              <div class="card">
                <div class="card-body">
                  <h4 class="mb-4">Newsletter</h4>
                  <form class="my-2" @submit.prevent="newsletter">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <input type="text" class="form-control" v-model="news_letter.value.title" placeholder="Title" required>
                      </div>

                      <div class="form-group">
                        <textarea rows="5" class="form-control" required placeholder="Description" v-model="news_letter.value.description"></textarea>
                      </div>
                      <div class="form-group">
                        <button class="btn btn-primary">Save Change</button>
                      </div>
                    </div>
                  </div>
                  </form>
                </div>
              </div></div>
        </div>
      </div>

      <div class="col-md-6"> 
        <div class="row">
          <div class="col-md-12 my-2">
            <div class="card">
              <div class="card-body">
                <h4 class="mb-4">At Glance</h4>
                <form class="my-2" @submit.prevent="at_Glance">
                <div class="row">
                  <div class="col-md-12">
                    
                    <div class="form-group">
                      <input type="text" class="form-control" v-model="atGlance.value.title" placeholder="Title" required>
                    </div>
                    <div class="form-group">
                      <textarea class="form-control" rows="5" required placeholder="Description" v-model="atGlance.value.description"></textarea>
                    </div>
                    <div class="form-group">
                      <input type="file" class="form-control-file" id="atGlance" @change="image($event, atGlance)">
                    </div>
                    <div class="form-group">
                      <button class="btn btn-primary">Save Change</button>
                    </div>
                  </div>
                </div>
                </form>
              </div>
            </div></div>
          <div class="col-md-12 my-2">
              <div class="card">
                <div class="card-body">
                  <h4 class="mb-4">Side Banner</h4>
                  <form class="my-2" @submit.prevent="sideBanner">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <input type="text" class="form-control" v-model="side_Banner.value.title" placeholder="Title" required>
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control" v-model="side_Banner.value.link" placeholder="Link" required>
                      </div>
                      <div class="form-group">
                        <textarea class="form-control" required rows="5" placeholder="Description" v-model="side_Banner.value.description"></textarea>
                      </div>
                      <div class="form-group">
                        <input type="file" class="form-control-file" id="side_Banner" @change="image($event, side_Banner)">
                      </div>
                      <div class="form-group">
                        <button class="btn btn-primary">Save Change</button>
                      </div>
                    </div>
                  </div>
                  </form>
                </div>
              </div></div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>


import { onMounted } from 'vue';
import { mapState } from 'vuex';
import myAxios from "../../service/axios.js";
import 'bootstrap';

export default {
  setup(){
    onMounted(()=>{})
  },
  data() {
      return {
        setupData : [],
        topBanner : { key : "top_banner", value : { title : "", link : "", description : "",image : "" }
        },
        atGlance : { key : "at_Glance", value : { title : "", description : "",image : "" }
        },
        side_Banner : { key : "side_Banner", value : { title : "", link : "", description : "",image : "" }
        },
        crash_course : { key : "crash_course", value : { link : "" }},
        news_letter : { key : "news_letter", value : { title : "", description : ""},
        },
      }
  },
  computed : {
    ...mapState('setup', {
      userlist : state => state.userlist
    })
  },
  methods: {
    async getAllData(){
      try{
        await myAxios
        .get("getSetup", {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken')
          }
        })
        .then((response) => {

      /* topBanner */
          let topBanner = response.data.data.find((data) => data.key == "top_banner");
          this.topBanner.value.title        = topBanner?.value?.title;
          this.topBanner.value.link         = topBanner?.value?.link;
          this.topBanner.value.description  = topBanner?.value?.description;      

      /* atGlance */
          let atGlance = response.data.data.find((data) => data.key == "at_Glance");
          this.atGlance.value.title        = atGlance?.value?.title;
          this.atGlance.value.description  = atGlance?.value?.description;
      
      /* atGlance */
          let side_Banner = response.data.data.find((data) => data.key == "side_Banner");
          this.side_Banner.value.title        = side_Banner?.value?.title;
          this.side_Banner.value.link         = side_Banner?.value?.link;
          this.side_Banner.value.description  = side_Banner?.value?.description;      

      /* atGlance */
          let crash_course = response.data.data.find((data) => data.key == "crash_course");
          this.crash_course.value.title        = crash_course?.value?.title;
          this.crash_course.value.link         = crash_course?.value?.link;
          this.crash_course.value.description  = crash_course?.value?.description;

      /* atGlance */
          let news_letter = response.data.data.find((data) => data.key == "news_letter");
          this.news_letter.value.title        = news_letter?.value?.title;
          this.news_letter.value.description  = news_letter?.value?.description;



        })
      }catch(err){
        console.log(err);
      }
    },
    image(e, obj){
      obj.value.image = e.target.files[0];
    },
    async topBanner_update() {
      try{
        await myAxios
        .post("admin/createSetup", this.topBanner, {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken'),
            "Content-Type" : "multipart/form-data"
          }
        })
        .then(() => {
          this.getAllData();
          document.getElementById("topBanner").value = "";
          this.toastr.success('Updated successfully','Top Banner');
          this.topBanner.value.image = "";
        })
      }catch(err){
        console.log(err);
      }
    },
    async at_Glance() {
      try{
        await myAxios
        .post("admin/createSetup", this.atGlance, {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken'),
            "Content-Type" : "multipart/form-data"
          }
        })
        .then(() => {
          this.getAllData();
          document.getElementById("atGlance").value = "";
          this.toastr.success('Updated successfully','At Glance');
          this.atGlance.value.image = "";
        })
      }catch(err){
        console.log(err);
      }
    },
    async sideBanner() {
      try{
        await myAxios
        .post("admin/createSetup", this.side_Banner, {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken'),
            "Content-Type" : "multipart/form-data"
          }
        })
        .then(() => {
          this.getAllData();
          document.getElementById("side_Banner").value = "";
          this.toastr.success('Updated successfully','Side Banner');
          this.side_Banner.value.image = "";
        })
      }catch(err){
        console.log(err);
      }
    },
    async crashcourse() {
      try{
        await myAxios
        .post("admin/createSetup", this.crash_course, {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken')
          }
        })
        .then(() => {
          this.getAllData();
          this.toastr.success('Updated successfully','Crash Course');
        })
      }catch(err){
        console.log(err);
      }
    },
    async newsletter() {
      try{
        await myAxios
        .post("admin/createSetup", this.news_letter, {
          headers : {
            "Authorization" : "Bearer "+localStorage.getItem('accessToken')
          }
        })
        .then(() => {
          this.getAllData();
          this.toastr.success('updated successfully','Newsletter');
        })
      }catch(err){
        console.log(err);
      }
    }

  },
  mounted(){
    this.getAllData();
  },
  components : {
  },
  created(){
    document.title = "Agency List";
  }
};
</script>