<template>
	<div class="col-md-2 bg-dark" :style="{'min-height' : sHeight+'px'}">
      <div id="accordian">
        <ul class="list-unstyled side-menu my-3">
              <li class="p-1 tree">
                <router-link :to="{name : 'dashboard'}"><i class="fa fa-home"></i> Home</router-link>
              </li>

              <li class="p-1 tree">
                <a href="#select-4" data-toggle="collapse"><i class="fa fa-caret-square-o-down" aria-hidden="true"></i>Card</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'CardPage' }" id="select-4" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link :to="{name : 'CardPage'}">Add card</router-link>
                      </li>
                  </ul>
              </li>
              <!-- <li class="p-1 tree">
                <a href="#select-5" data-toggle="collapse"><i class="fa fa-crosshairs" aria-hidden="true"></i>Satisfied</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'SatisfiedStudent' }" id="select-5" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link :to="{name : 'SatisfiedStudent'}">Add Satisfied</router-link>
                      </li>
                  </ul>
              </li> -->
              <li class="p-1 tree">
                <a href="#select-6" data-toggle="collapse"><i class="fa fa-list-alt" aria-hidden="true"></i>Posts</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'PostPage' }" id="select-6" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link :to="{name : 'PostPage'}">Add Post</router-link>
                      </li>
                  </ul>
              </li>
              <!-- <li class="p-1 tree">
                <a href="#select-7" data-toggle="collapse"><i class="fa fa-list-alt" aria-hidden="true"></i>Category</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'category' }" id="select-7" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link :to="{name : 'category'}">Add Category</router-link>
                      </li>
                  </ul>
              </li> -->
              <!-- <li class="p-1 tree">
                <a href="#select-8" data-toggle="collapse"><i class="fa fa-university" aria-hidden="true"></i>University</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'university' }" id="select-8" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link :to="{name : 'university'}">Add University</router-link>
                      </li>
                  </ul>
              </li> -->

              <!-- <li class="p-1 tree">
                <a href="#select-9" data-toggle="collapse"><i class="fa fa-file-text" aria-hidden="true"></i>Documents</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'documents' }" id="select-9" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link :to="{name : 'documents'}">Add Document</router-link>
                      </li>
                  </ul>
              </li> -->

              <!-- <li class="p-1 tree">
                <a href="#select-10" data-toggle="collapse"><i class="fa fa-book" aria-hidden="true"></i>Exam</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'exam' }" id="select-10" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link :to="{name : 'exam'}">Add Exam</router-link>
                      </li>
                  </ul>
              </li>  -->             


              <!-- <li class="p-1 tree">
                <a href="#select-11" data-toggle="collapse"><i class="fa fa-users" aria-hidden="true"></i>Users</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'studentlist' || $route.name == 'agencylist' || $route.name == 'adminlist' }" id="select-11" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link :to="{name : 'studentlist'}">Student List</router-link>
                      </li>
                      <li class="p-1 tree">
                        <router-link :to="{name : 'agencylist'}">Agency List</router-link>
                      </li>
                      <li class="p-1 tree">
                        <router-link :to="{name : 'adminlist'}">Admin List</router-link>
                      </li>
                  </ul>
              </li> -->

              <li class="p-1 tree">
                <a href="#select-12" data-toggle="collapse"><i class="fa fa-book" aria-hidden="true"></i>Pages</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'PageList' || $route.name == 'PageCreate' || $route.name == 'PageUpdate' }" id="select-12" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link :to="{name : 'PageCreate'}">Add New</router-link>
                      </li>
                      <li class="p-1 tree">
                        <router-link :to="{name : 'PageList'}">Page List</router-link>
                      </li>
                  </ul>
              </li>

              <li class="p-1 tree">
                <a href="#select-13" data-toggle="collapse"><i class="fa fa-envelope-open-o" aria-hidden="true"></i>Message</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'FeedbackPage' || $route.name == 'NewsLetter' }" id="select-13" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link :to="{name : 'FeedbackPage'}">Feedback</router-link>
                      </li>
                      <li class="p-1 tree">
                        <router-link :to="{name : 'NewsLetter'}">NewsLetter</router-link>
                      </li>
                  </ul>
              </li>

              <li class="p-1 tree">
                <a href="#select-3" data-toggle="collapse"><i class="fa fa-plug" aria-hidden="true"></i>Configuration</a>
                  <ul class="collapse list-unstyled ml-4" :class="{'show' : $route.name == 'SetupPage' || $route.name == 'NavList' || $route.name == 'SliderPage' }" id="select-3" data-parent="#accordian">
                      <li class="p-1 tree">
                        <router-link :to="{name : 'SetupPage'}">Setup</router-link>
                      </li>
                      <li class="p-1 tree">
                        <router-link :to="{name : 'SliderPage'}">Slider</router-link>
                      </li>
                      <li class="p-1 tree">
                        <router-link :to="{name : 'NavList'}">Navbar</router-link>
                      </li>
                  </ul>
              </li>


              <li class="p-1 tree">
                <a href="#" @click.prevent="edit()"><i class="fa fa-cog" aria-hidden="true"></i>Settings</a>
              </li>
        </ul>
      </div>
  </div>


  <!-- Insert modal -->
<div class="modal fade" id="updateSettings" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Settings</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
      <form enctype="multipart/form-data" @submit.prevent="insert">
        <p class="text-center text-danger" v-if="$store?.state?.errors?.network_error">{{$store.state.errors.network_error}}</p>
        <div class="row">
          
          <div class="col-md-6 my-2">
            <input type="text" class="form-control" v-model="settings.title" placeholder="Site Name">
          </div>

          <div class="col-md-6 my-2">
            <input type="text" class="form-control" v-model="settings.phone" placeholder="Phone">
          </div>

          <div class="col-md-6 my-2">
            <input type="text" class="form-control" v-model="settings.email" placeholder="Email">
          </div>

          <div class="col-md-6 my-2">
            <input type="text" name="name" class="form-control" v-model="settings.address" placeholder="Address">
          </div>

          <div class="col-md-12 my-2">
            <textarea class="form-control" v-model="settings.map" placeholder="Google Map"></textarea>
          </div>

          <div class="col-md-6 my-2">
            <input type="file" class="form-control-file" @change="image($event, 1)">
            <small>Site Logo</small>
          </div>
          <div class="col-md-6 my-2">
            <input type="file" class="form-control-file" @change="image($event, 2)">
            <small>About Us Image</small>
          </div>
        </div>

        <div class="text-right">
          <TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
        </div>
      </form>
      </div>
    </div>
  </div>
</div>

</template>

<script>

import TheButton from "../../../helper/TheButton.vue";
import { onMounted } from 'vue';
import $ from 'jquery';
import 'bootstrap';

let updateModal = null;

export default {
  name: 'SideBarPage',
  setup(){
    onMounted(()=>{
      updateModal = $("#updateSettings");
    })
  },
  data () {
    return {
      sHeight : window.innerHeight - 120,
      settings : {title : '',phone : '', email : '', address : '', map : '', logo : '', favicon : ''}
    }
  },
  methods : {
    image(event, index){
      if(index == 1){
        this.settings.logo    = event.target.files[0] ? event.target.files[0] : "";
      }else if(index == 2){
        this.settings.favicon = event.target.files[0] ? event.target.files[0] : "";
      }
    },
    edit(){
      updateModal.modal("show");
      this.settings.title      = this.$store?.state?.siteData?.title;
      this.settings.phone      = this.$store?.state?.siteData?.phone;
      this.settings.email      = this.$store?.state?.siteData?.email;
      this.settings.address    = this.$store?.state?.siteData?.address;
      this.settings.map        = this.$store?.state?.siteData?.map;
      this.settings.logo       = "";
      this.settings.favicon    = "";
    },
    insert(){
      this.$store.dispatch("siteDataUpdate", {data : this.settings, myModal : updateModal});
    }
  },
  components : {
    TheButton
  }
}
</script>

<style lang="css" scoped>
</style>