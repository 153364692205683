<template>
	<div class="container mt-3">
	<form @submit.prevent="signup">
		<div class="mb-3">
			<input type="text" class="form-control" v-model="myData.name" placeholder="Name">
		</div>
		<div class="mb-3">
			<input type="email" class="form-control" v-model="myData.email" placeholder="Email">
		</div>
		<div class="mb-3">
			<input type="password" class="form-control" v-model="myData.password" placeholder="Password">
		</div>
		<button type="submit" class="btn btn-primary">Submit</button>
	</form>
	</div>
	<router-link to="/login" class="nav-link text-center">Login</router-link>
	<div class="loader" v-if="loadding"></div>
</template>

<script>

// import myaxios from "../service/axios.js";

export default {

  name: 'RegisterPage',

  data () {
	return {
		loadding : false,
		myData : {
			name : '',
			email : '',
			user_type : '',
			password : ''
		}
	}
  },
  methods : {
	async signup(){
		// try{
		// 	await this.axios
		// 	.post("register", this.myData)
		// 	.then((response) => {
		// 		localStorage.setItem('accessToken', response.data.access_token);
		// 	}).
		// }catch(err){
		// 	console.log(err.response.data.errors);
		// }
		this.loadding = true;
		await this.axios
			.post("register", this.myData)
			.then((response) => {
				this.loadding = false;
				localStorage.setItem('accessToken', response.data.access_token);
				this.$router.push("/");
			})
			.catch((res) => {
				this.loadding = false;
				console.log("catch", res);
			})
			.finally((response) => {
				this.loadding = false;
				console.log("finally", response);
			})

	}
  },
  created(){
    document.title = "Register List";
  }
}
</script>

<style lang="css" scoped>
</style>