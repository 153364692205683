<template>
    <SliderPage></SliderPage>
<!--Banner - Icons-->
<loadingSection v-if="singleloading"></loadingSection>
<section v-else id="home-icon" class="py-5 text-left" style="background: #012169">
  <div class="container" v-if="top_banner && top_banner.value">
    <div class="row">
      <div class="col-md-5 text-center">
        <img class="rounded" :src="top_banner.value.image">
      </div>
      <div class="col-md-7 text-white">
        <h3 class="my-3">{{top_banner.value.title}}</h3>
        <p class="p">{{top_banner?.value?.description}}</p>
        <a
          v-if="top_banner?.value?.link.startsWith('http')"
          :href="top_banner?.value?.link"
          class="btn btn-danger my-2" 
          style="border-radius: 20px !important;"
        >Know More</a>
        <router-link v-else :to="top_banner?.value?.link" class="btn btn-danger my-2" 
          style="border-radius: 20px !important;">Know More</router-link>
      </div>
    </div>
  </div>
</section>


<!--Home - Icons-->
<loadingSection v-if="get_Card_Data.loading"></loadingSection>
<section v-else id="home-icon" class="py-5 text-center gears" style="background: #fbeeee">
  <div class="container" v-if="get_Card_Data && get_Card_Data.data">
    <div class="row">
      <div class="col-md-4" v-for="data in get_Card_Data.data" :key="data.title">
        <i :class="data.icon" class="icon"></i>
        <h3 class="my-2">{{data.title}}</h3>
        <p class="lead">{{data.description}}</p>
      </div>
    </div>
  </div>
</section>


<!--Getting Started-->
  <!-- <section v-if="at_Glance && at_Glance.value">
    <loadingSection v-if="singleloading"></loadingSection>
    <section 
      id="getting-started" 
      class="py-5 text-center bg-dark text-light"
      :style="{background: 'url('+ at_Glance.value.image +')'}"
      v-else
    >
      <div class="img-ovarlay">
        <div class="container">
          <div class="row">
            <div class="col mt-5 py-4">
              <h2>{{at_Glance.value.title}}</h2>
              <p class="lead">{{at_Glance.value.description}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section> -->

<!--Home-Banner-->
<loadingSection v-if="singleloading"></loadingSection>
<section v-else id="home-banner" style="background: #0a334f;
    color: #fff;" class="py-5">
    <div class="container" v-if="side_Banner && side_Banner.value">
      <div class="row">
        <div class="col-md-6 align-self-center">
          <h3>{{side_Banner.value.title}}</h3>
          <p class="lead">{{side_Banner.value.description}}</p>

          <a
          v-if="side_Banner.value.link.startsWith('http')"
          :href="side_Banner.value.link"
          class="btn btn-danger mb-3 mb-sm-3 mb-md-0" 
          style="border-radius: 20px !important;"
        >Read More</a>
        <router-link v-else :to="side_Banner.value.link" class="btn btn-danger mb-3 mb-sm-3 mb-md-0" 
          style="border-radius: 20px !important;">Read More</router-link>

        </div>
        <div class="col-md-6">
          <img :src="side_Banner.value.image" class="img-fluid rounded" alt="">
        </div>
      </div>
    </div>
  </section>


<!--Play Started-->
<section id="play-section" class="py-5 text-center bg-dark text-light">
    <div class="img-ovarlay">
      <div class="container" v-if="crash_course && crash_course.value">
        <div class="row">
          <div class="col mt-5 py-4" uk-lightbox>
            <a :href="crash_course.value.link">
              <i class="fa fa-play"></i>
            </a>
            <h2 class="mt-3">Unlock Your Productivity - Inducing Endesk</h2>
          </div>
        </div>
      </div>
    </div>
</section>


<!-- Happy Students -->
  <!--- <section v-if="get_Satisfied && get_Satisfied.data">
      <loadingSection v-if="get_Satisfied.loading"></loadingSection>
      <section v-else id="home-banner" class="py-5">
        <div class="container" v-if="get_Satisfied && get_Satisfied.data">
          <div class="row">
            <div class="col-md-12 text-center">
              <h3 class="my-2 mb-5">A Glimpse of Our Happy Students</h3>
            </div>
            <div class="col-md-12 text-center">

            <Carousel :autoplay="2000" :breakpoints="breakpoints" :wrap-around="true">
              <Slide v-for="data in get_Satisfied.data" :key="data.name">
                <div class="carousel__item">
                  <img :src="data.image" class="img-fluid rounded-circle m-auto" alt="image1" style="height: 210px;">
                  <div class="card-body">
                    <div class="card-title text-center">
                        <h4>{{data.name}}</h4>
                        <p>{{data.description}}</p>
                    </div>
                  </div>
                </div>
              </Slide>
            </Carousel>

                </div>
            </div>
            </div>
      </section>
  </section> -->

<!--Post - Icons-->
<!-- <loadingSection v-if="get_posts.loading"></loadingSection>
<section v-else id="home-icon" class="py-5 text-center" style="background: #fff6f6">
  <div class="container-fluid" v-if="get_posts && get_posts.data">
    <div class="row mx-lg-5 px-lg-5">
        <div class="col-md-12">
          <h3 class="mb-5 my-2">Checkout Our Latest Posts</h3>
        </div>
        <div class="col-md-3 col-12" v-for="data in get_posts.data" :key="data.title">
          <div class="card mt-2">
            <img :src="data.image" class="card-img-top" :alt="data.title">
            <div class="card-body">
              <div class="card-title">
                <h4>{{data.title}}</h4>
                <small>Written By {{data.written}}</small>
                <hr>
                <p class="lead" v-text="data.description.replace(/<[^>]+>/g, '').substring(0, 110)"></p>
                  <RouterLink class="btn btn-block btn-dark" :to="{ name: 'PostDetails', params : {slug : data.slug} }">Read More</RouterLink>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</section> -->


<!--News letter-->
<loadingSection v-if="singleloading"></loadingSection>
<section v-else id="news-letter" class="py-5 bg-dark text-light">
  <div class="container" v-if="news_letter && news_letter.value">
    <div class="row">
      <div class="col text-center">
        <h2>{{news_letter.value.title}}</h2>
        <p class="lead">{{news_letter.value.description}}</p>

        <form @submit.prevent="newlettersend" class="form-inline justify-content-center">
          <label for="" class="sr-only">Name</label>
          <input type="text" class="form-control m-2" v-model="newletters.name" placeholder="Enter name" required>
          <label for="" class="sr-only">Email</label>
          <input type="email" class="form-control m-2" v-model="newletters.email" placeholder="Enter email" required>
          <input type="submit" class="btn btn-primary m-2" value="Subscribe">
        </form>

      </div>
    </div>
  </div>
</section>
  

</template>

<script>

import SliderPage from "./inc/SliderPage.vue";
import myAxios from "../../service/axios.js";
import loadingSection from "./SkeletionPageLarge.vue";


export default {
  name: 'LandingPage',
  data () {
    return {
      breakpoints: {
        700: {
        itemsToShow: 3,
          snapAlign: 'center',
        },
        1024: {
        itemsToShow: 4,
          snapAlign: 'center',
        },
      },
      top_banner    : {data : []},
      news_letter   : {data : []},
      crash_course  : {data : []},
      side_Banner   : {data : []},
      at_Glance     : {data : []},
      get_Card_Data : {data : [], loading :  false},
      get_Satisfied : {data : [], loading :  false},
      get_posts     : {data : [], loading :  false},
      loading       : false,
      newletters    : {name: "", email: ""}
    }
  },
  mounted(){
    this.getData();
    this.getCardData();
    this.getposts();
    this.getSatisfied();
  },
  methods : {
    async getData(){
      this.singleloading = true;
      try{
        await myAxios
        .get("getSetup")
        .then((response) => {
        this.singleloading = false;
      /*top_banner*/
         let top_banner = response.data.data.find((data) => data.key == "top_banner");
         this.top_banner = top_banner;

    /*news_letter*/
         let news_letter = response.data.data.find((data) => data.key == "news_letter");
         this.news_letter = news_letter;

    /*at_Glance*/
         let at_Glance = response.data.data.find((data) => data.key == "at_Glance");
         this.at_Glance = at_Glance;

    /*side_Banner*/
         let side_Banner = response.data.data.find((data) => data.key == "side_Banner");
         this.side_Banner = side_Banner;

    /*crash_course*/
         let crash_course = response.data.data.find((data) => data.key == "crash_course");
         this.crash_course = crash_course;
        })
      }catch(err){
        this.singleloading = true;
      }
    },
    async getCardData(){
      this.get_Card_Data.loading = true;
      try{
        await myAxios
        .get("getCardSection")
        .then((response) => {
          this.get_Card_Data.data = response.data.data;
          this.get_Card_Data.loading = false;
        })
      }catch(err){
        this.get_Card_Data.loading = true;
      }
    },    
    async getSatisfied(){
      this.get_Satisfied.loading = true;
      try{
        await myAxios
        .get("getSatisfied")
        .then((response) => {
          this.get_Satisfied.data    = response.data.data;
          this.get_Satisfied.loading = false;
        })
      }catch(err){
        this.get_Satisfied.loading = true;
      }
    },
    async getposts(){
      this.get_posts.loading = true;
      try{
        await myAxios
        .get("getPost")
        .then((response) => {
          this.get_posts.data = response.data.data;
          this.get_posts.loading = false;
        })
      }catch(err){
        this.get_posts.loading = true;
      }
    },
    async newlettersend(){
      try{
        await myAxios
        .post("insertNewsLetter", this.newletters)
        .then(() => {
          this.newletters.name = "";
          this.newletters.email = "";
          this.toastr.success('Thank you for your Subscriber','Subscriber');
        })
      }catch(err){
        console.log(err)
      }
    },
  },
  components : {
    SliderPage,
    loadingSection
  },
  created(){
    document.title = "Enrich IT Solutions | Premier Web Development & Networking Services";
  }
}
</script>

<style lang="css" scoped>
</style>