<template>
  <!--Contact-->
    <section id="services" class="text-center text-light" :style="{background: 'url('+ contact_us.image +')'}">
      <div class="img-ovarlay">
        <div class="container">
          <div class="row">
            <div class="col pt-5">
              <h2>{{contact_us.title}}</h2>
            </div>
          </div>
        </div>
      </div>
    </section>

<!--Contact-us-->
    <section id="contact-us" class="py-5">
        <div class="container">
          <div class="row">

            <div class="col-md-8">
              <div class="card">
                <div class="card-body">
                  <h4 class="text-center">Please fill out this form to contact us</h4>
                  <form @submit.prevent="send_message">
                    <div class="row mt-4">
                    <div class="col-md-6">
                      <div class="form-group">
                          <input type="text" class="form-control" v-model="contactData.firstName" placeholder="First name" required>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input type="text" class="form-control" v-model="contactData.lastName" placeholder="Last Name" required>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input type="email" class="form-control" v-model="contactData.email" placeholder="Email" required>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <input type="text" class="form-control" v-model="contactData.phone" placeholder="Phone number" required>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <textarea name="message" v-model="contactData.message" placeholder="Message" class="form-control" rows="5" required></textarea>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <button type="submit" class="btn btn-block btn-dark">Send</button>
                    </div>
                  </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <h4>Get In Touch</h4>
                  <p>{{get_in_touch}}</p>
                  <h4>Address</h4>
                  <p>
                    {{$store?.state?.siteData?.address}}
                  </p>

                  <h4>Email</h4>
                  <p>{{$store?.state?.siteData?.email}}</p>

                  <h4>Phone</h4>
                  <p class="mb-2">{{$store?.state?.siteData?.phone}}</p>

                </div>
              </div>
            </div>

          </div>
      </div>
    </section>
</template>

<script>
import myAxios from "../../service/axios.js";
export default {

  name: 'ContactPage',

  data () {
    return {
      contactData : {
        firstName : "",
        lastName  : "",
        email     : "",
        phone     : "",
        message   : ""
      },
      contact_us : {
          image : "https://endesk.net/backend/public/images/9ab885d424.jpeg",
          title : "Contact Us",
          descripton : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, saepe."
        },
      get_in_touch : "Ready to elevate your IT solutions? Contact Endesk today and discover how we can support your business with tailored IT outsourcing services."
    }
  },
  methods : {
    async send_message(){

      try{
        await myAxios
        .post("insertContact", this.contactData)
        .then(() => {
          this.contactData = {firstName:"", lastName: "", email:"",phone:"", message:""}
          this.toastr.success('Send your message');
        })
      }catch(err){
        // console.log(err)
      }


    }

  },
  created(){
    document.title = "Contact Us";
  }
}
</script>

<style lang="css" scoped>
</style>