<template>
  <section id="contact-us" class="py-5" style="background: rgb(1, 33, 105);
    color: #fff;">
      <div class="container">
        <div v-html="exporeData?.description"></div>
      </div>
  </section>
</template>
<script>

import myAxios from "../../service/axios.js";

export default {
  data() {
      return {
        exporeData : []
      }
  },
  methods: {
    async getPageData() {
      try{
        await myAxios
        .get("pagebyslug", {
          params : {
            slug : this.$route.params.slug
          }
        })
        .then((res) => {
          this.exporeData = res?.data?.data;   
        })
      }catch(err){
        if(err?.response?.data?.status === false){
          this.$router.push('/');
        }
      }
      document.title = this.exporeData?.slug
    }
  },
  mounted(){
    this.getPageData();
  }
};
</script>