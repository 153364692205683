<template>
  <div class="col-md-10">
       <div class="user-update py-3">
            <div class="heading">
                <h4 class="m-0 pb-3 mb-4 border-bottom">Edit Profile</h4>
            </div>
            <div class="card">
				<div class="card-body">
					<img :src="imageShow" style="height: 110px;">
					<form class="my-2" @submit.prevent="update">
					<div class="row">
						<div class="col-md-12">
							<div class="form-group">
								<input type="file" class="form-control-file" @change="image">
							</div>
							<div class="form-group">
								<input type="text" class="form-control" v-model="updateProfile.name" placeholder="Enter name">
							</div>

							<TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
						</div>
					</div>
					</form>
				</div>
            </div>
        </div>
        <div class="heading mt-5">
			<h4 class="m-0 pb-3 mb-4 border-bottom">Change Password</h4>
		</div>
		<div class="card">
			<div class="card-body">
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<form class="my-2" @submit.prevent="passwordChange">
							<div class="form-group">
								<label>Old Password *</label>
								<input type="password" v-model="password.old_password" class="form-control" placeholder="Password">
								<small v-if="$store?.state?.errors?.noMatch" class="form-text text-danger">{{$store?.state?.errors?.noMatch}}</small>
							</div>
							<div class="form-group">
								<label>New Password *</label>
								<input type="password" v-model="password.new_password" class="form-control" placeholder="Password">
								<small v-if="$store?.state?.errors?.match" class="form-text text-success">{{$store?.state?.errors?.match}}</small>
							</div>
							<TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
						</form>
					</div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>

import TheButton from "../../helper/TheButton.vue";
import 'bootstrap';
import { onMounted } from 'vue';
import myAxios from "../../service/axios.js";

export default {
   setup(){
	onMounted(()=>{

	})
  },
  data() {
	return {
		imageShow 	: null,
		password : {
			old_password : '',
			new_password : ''
		},
		updateProfile : {
			name 	: '',
			avatar 	: ''
		}
	}
  },
  methods: {
	image(event){
		if(event.target.files[0] != ""){
			if(event.target.files[0]?.type == "image/jpeg" || event.target.files[0]?.type == "image/png"){
				const reader = new FileReader();
				reader.onload = (data) => {
					this.imageShow 				= data.target.result;
					this.updateProfile.avatar 	= event.target.files[0];
				}
				reader.readAsDataURL(event.target?.files[0]);
			}
		}
	},
	async getUserData(){
		try{
				await myAxios
				.get("user",{
					headers : {
						"Authorization" : "Bearer "+localStorage.getItem('accessToken')
					}
				})
				.then((response) => {
					this.updateProfile.name = response.data.data.name;
					this.imageShow 			= response.data.data.avatar;
				})
			}catch(err){
				this.$store.commit('loadding', false, {root: true});
				console.log("Response: ", err);
				if(err.response.data.message == "Unauthenticated."){
					this.$store.context.commit("autologout");
				}
			}
	},
	update(){
		this.$store.dispatch("updateProfile", this.updateProfile);
	},
	passwordChange(){
		this.$store.dispatch("passwordUpdate", this.password);
	}
  },
  mounted(){
	this.getUserData();
  },
  components : {
	TheButton
  },
  created(){
    document.title = "Admin Profile"
  }
};
</script>