<template>
  <HeaderPage></HeaderPage>
	<p>Welcome to agency dashboard</p>
</template>

<script>
import HeaderPage from "../admin/inc/HeaderPage.vue";
export default {

  name: 'DashboardPage',

  data () {
    return {

    }
  },
  components : {
    HeaderPage
  }
}
</script>

<style lang="css" scoped>
</style>