<template>
    <section id="copyright-section" class="py-3 text-center text-light">
        <div class="container">
          <div class="row">
            <div class="col">
              <p class="lead mb-0">&copy; {{new Date().getFullYear()}} {{$store?.state?.siteData?.title}}</p>
              <a class="d-block text-center text-white pt-2" href="https://www.enrichitsolutions.com">Develop by Enrich IT Solutions</a>
            </div>
          </div>
        </div>
    </section>
</template>

<script>
export default {

  name: 'FooterPage',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>