<template>
	<div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div style="overflow: hidden;">
              <h3 class="float-left">Page List</h3>
            </div>
            <hr>

      <form enctype="multipart/form-data" @submit.prevent="insert">
				<p class="text-center text-danger" v-if="$store?.state?.errors?.network_error">{{$store?.state?.errors?.network_error}}</p>
          <div class="col-md-12 my-2">
            <input type="text" name="name" class="form-control" v-model="insertData.name" placeholder="Page Name" required>
          </div>
          <div class="col-md-12 my-2">
						<ckeditor :editor="editor" v-validate="'required'" @ready="onReady" v-model="insertData.description"></ckeditor>
          </div>
				<div class="text-left ml-3">
					<TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
				</div>
      </form>
						
						<div class="loader" v-if="$store.state.loadding"></div>
            </div>
          </div>
        </div>
  </div>

</template>

<script>

import TheButton from "../../helper/TheButton.vue";
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

export default {
  data() {
			return {
				insertData : {name : '', description : ''},

				updateData : {id : '',name : '', avatar : '', address : '',status : ''},
				editor: DecoupledEditor
			}
  },
  methods: {
		onReady( editor )  {
					editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element,editor.ui.getEditableElement());
		},
		insert(){
			if(this.insertData.description == ""){
				this.toastr.error('Description field must not be empty.','Error');
				return false;
			}
			this.$store.dispatch("page/insert", this.insertData);
			this.insertData = {name : '', description: ''};
			this.toastr.success('Inserted successfully','Success');
		},
		edit(data){
			this.updateData.id 			= data.id;
			this.updateData.name 		= data.name;
			this.updateData.status 	= data.status;
		},
		update() {
			this.$store.dispatch("page/update", {});
			this.updateData.avatar 	= "";
		},
		addMoreRemove(remove, index){
			this.insertData.splice(index, 1);
		},
		remove(data) {
			if(confirm("Are you sure delete?")){
				this.$store.dispatch("page/remove", data);
			}
		}
  },
	mounted(){
		this.$store.dispatch("page/getData");
	},
	components : {
		TheButton
	},
	created(){
    document.title = "Pages Create"
  }
};
</script>
<style lang="css" scoped>
	.ck-editor__editable {
		min-height: 300px;
		border-left: 1px solid #ddd;
		border-right: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
	}

</style>