<template>

<loadingSection v-if="loading"></loadingSection>
<section v-else>
<!--blog-->
<section id="services" class="text-center text-light" :style="{background: 'url('+ blog_setup.image +')'}">
  <div class="img-ovarlay">
    <div class="container">
      <div class="row">
        <div class="col pt-5 mt-5">
          <h2>{{blog_setup.title}}</h2>
        </div>
      </div>
    </div>
  </div>
</section>

<!--blog-img-->
<section id="blog-img" class="py-5">
<div class="container">
  <div class="row">
    <div class="col-md-4" v-for="blog in blogs" :key="blog.title">
      <div class="card mt-2">
        <img :src="blog.image" class="card-img-top" alt="Image-1" style="max-height: 250px">
        <div class="card-body">
          <div class="card-title">
            <h4>{{blog.title}}</h4>
            <hr>
            <p class="lead" v-text="blog.description.replace(/<[^>]+>/g, '').substring(0, 110)"></p>
              <RouterLink class="btn btn-block btn-dark" :to="{ name: 'PostDetails', params : {slug : blog.slug} }">Read More</RouterLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</section>
</section> 

</template>

<script>

import myAxios from "../../service/axios.js";
import loadingSection from "./SkeletionPageLarge.vue";

export default {
  name: 'BlogPage',
  data () {
	return {
		loading : false,
		blog_setup : {
		image : "https://endesk.net/backend/public/images/9ab885d424.jpeg",
		title : "Blogs",
		descripton : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, saepe."
		},
		blogs : null
	}
  },
  mounted(){
	this.getCardData();
  },
  computed : {
    strippedDescription() {
      return this.blogs.description.replace(/<[^>]+>|&nbsp;|&[^;]+;/g, '');
    }
  },
  methods : {

	async getCardData(){
		this.loading = true;
		try{
			await myAxios
			.get("getPost")
			.then((response) => {
				this.loading = false;
				this.blogs = response.data.data;
			})
		}catch(err){
			this.loading = true;
			//err
		}
	}

  },
components : {
loadingSection
},
created(){
  document.title = "Blog List";
}
}
</script>

<style lang="css" scoped>
</style>