<template>
  <div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div style="overflow: hidden;">
              <h3 class="float-left">Agency List</h3>
            </div>
            <hr>
            <DataTable :data="userlist" :columns="columnss" class="table table-striped">
              <thead>
                <tr>
                  <th>SL</th>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Type</th>
                  <th>Status</th>
                </tr>
              </thead>
            <template #column-5="props">
              <span v-if="$store?.state?.users?.id != props?.rowData?.id" style="cursor: pointer;">
                <span v-if="props.rowData.status" @click="activeInactive(props.rowData)" class="badge bg-success">Active</span>
                <span v-else class="badge bg-danger p-2" @click="activeInactive(props.rowData)">Inactive</span>
              </span>
            </template>

            <template #column-1="props">
              <img v-if="props?.rowData?.avatar == null" :src="require(`@/assets/user.png`)" style="height: 60px;">
              <img else :src="props.rowData.avatar" alt="Image">
            </template>
            </DataTable>
            <div class="loader" v-if="$store.state.loadding"></div>
            </div>
          </div>
        </div>
  </div>
</template>

<script>


import { onMounted } from 'vue';
import { mapState } from 'vuex';
import 'bootstrap';

import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import  'datatables.net-bs4';
DataTable.use(DataTablesCore);
export default {
  setup(){
    onMounted(()=>{})
  },
  data() {
      return {
        columnss : [
            {data : null, render : function(data, type, row, meta){ return `${meta.row + 1}`}},
            {data : "avatar"},
            {data : "name"},
            {data : "email"},
            {data : "user_type"},
            {data : "status"},
        ]
      }
  },
  computed : {
    ...mapState('usertype', {
      userlist : state => state.userlist
    })
  },
  methods: {
    remove(data) {
      if(confirm("Are you sure delete?")){
        this.$store.dispatch("university/remove", data);
      }
    },
    activeInactive(data){
      this.$store.dispatch("usertype/activeInactive", data);
    }
  },
  mounted(){
    this.$store.dispatch("usertype/getAgency");
  },
  components : {
    DataTable
  },
  created(){
    document.title = "Agency List"
  }
};
</script>