import myAxios from "../../service/axios.js";
import router from "../../router.js";
export default {
	namespaced : true,
	state(){
		return {}
	},
	getters : {},
	mutations : {},
	actions : {
		async signup(context, payload){
			try{
				context.commit('loadding', true, {root: true});
				await myAxios
				.post("login", payload)
				.then((response) => {
					context.commit('loadding', false, {root: true});
					context.commit('users', response.data.data, {root: true});
					localStorage.setItem('accessToken', response.data.access_token);
					localStorage.setItem('user_type', response.data.data.user_type);

					if(response.data.data.user_type === "admin"){
						router.push({name : 'dashboard'});
					}else if(response.data.data.user_type === "agency"){
						router.push({name : 'Agency'});
					}else if(response.data.data.user_type === "student"){
						router.push({name : 'Student'});
					}
					


				})
			}catch(err){
				context.commit('loadding', false, {root: true});
				if(err.code == "ERR_NETWORK"){
					context.commit('errors', {network_error : err.message}, {root: true});
				}else{
					context.commit('errors', err?.response?.data?.errors, {root: true});
				}
				if(err?.response?.data?.message == "Unauthenticated."){
					context.commit("autologout",{}, {root: true});
				}
			}
		},
		async logout(context){
			try{
				await myAxios
				.get("logout", {
					headers : {
						"Authorization" : "Bearer "+localStorage.getItem('accessToken')
					}
				})
				.then(() => {
					localStorage.removeItem("accessToken");
					localStorage.removeItem("user_type");
					router.push({name : 'login'});
				})
			}catch(err){
				if(err.code == "ERR_NETWORK"){
					context.commit('errors', {network_error : err.message}, {root: true});
				}else{
					context.commit('errors', err.response.data.errors, {root: true});
				}
				if(err?.response?.data?.message == "Unauthenticated."){
					context.commit("autologout",{}, {root: true});
				}
			}
		}


	}
}