<template>
  <!--About Us-->
  <section id="about-us" class="text-center text-light" :style="{background: 'url('+ about_us.image +')'}">
      <div class="img-ovarlay">
        <div class="container">
          <div class="row">
            <div class="col pt-5 mt-5">
              <h2>{{about_us.title}}</h2>
              <!-- <p class="lead">{{about_us.descripton}}</p> -->
            </div>
          </div>
        </div>
      </div>
    </section>

<!--About-Banner-->
  <section id="about-banner" class="py-5" style="background: #fc940d">
  <div class="container">
  <div class="row">
    <div class="col-md-6 align-self-center">
      <h3>{{what_we_do.title}}</h3>
      <p style="color: #fff" class="lead">{{what_we_do.descripton_one}}</p>
      <p style="color: #fff" class="lead">{{what_we_do.descripton_two}}</p>
    </div>
    <div class="col-md-6 text-center">
      <img :src="$store?.state?.siteData?.favicon" class="img-fluid rounded-circle" alt="Picture">
    </div>
  </div>
  </div>
  </section>

<!--Icon Box-->
<!--   <section id="icon-box" class="py-5 text-center">
    <div class="container">
        <div class="row">
          <div class="col-md-4 my-2" v-for="icon_box in icon_boxs" :key="icon_box.title">
            <div class="card text-light" :class="icon_box.bgColor">
              <div class="card-body">
                <i :class="icon_box.icon"></i>
                <h3>{{icon_box.title}}</h3>
                <p class="lead">{{icon_box.descripton}}</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  </section> -->

<!--Testimonial-->
<!--   <section id="testimonial" class="py-5 bg-light text-dark">
    <div class="container">
      <div class="row">
        <div class="col text-center">
          <h2>Testimonial</h2>
        </div>
      </div>

      <div class="row mt-3 mx-2">
        <div class="col">
          <div class="slide">
            <blockquote class="blockquote" v-for="testimonial in testimonials" :key="testimonial.writer">
              <p class="lead">{{testimonial.descripton}}</p>
              <footer class="blockquote-footer">
                  {{testimonial.writer}} form <a :href="testimonial.link">Go to details</a>
              </footer>
            </blockquote>
          </div>
        </div>
      </div>

    </div>
  </section> -->
</template>

<script>

export default {

  name: 'AboutPage',

  data () {
    return {
        about_us : {
          image : "https://endesk.net/backend/public/images/9ab885d424.jpeg",
          title : "About Me",
          descripton : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eaque, saepe."
        },
        what_we_do : {
          image : "https://endesk.net/man.jpg",
          title : "",
          descripton_one : "Welcome to my professional profile! With over two decades of experience in ICT, I specialize in Systems and Networking. My expertise spans designing, implementing, and managing multi-protocol enterprise systems and networks, encompassing both wired and wireless environments. I excel in formulating and executing ICT projects and policies, with a focus on LAN/WAN, systems design, dial access, and security solutions.",
          descripton_two : "I bring proficiency in troubleshooting and maintaining Unix and Windows environments, along with expertise in virtualization and cloud VM management. My strong interpersonal skills complement my ability to manage large, complex projects effectively. Dedicated to continuous learning, I thrive in dynamic, multi-vendor environments, contributing to organizational objectives through proactive collaboration and teamwork.Explore my journey and achievements in ICT, and discover how my skills and dedication can benefit your organization.",
        },
        icon_boxs : [
            {
              title : "Lorem Ipsum",
              icon : "fa fa-envelope",
              bgColor : "bg-success",
              descripton : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla quasi magni placeat."
            },
            {
              title : "Lorem Ipsum",
              icon : "fa fa-car",
              bgColor : "bg-dark",
              descripton : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla quasi magni placeat."
            },
            {
              title : "Lorem Ipsum",
              icon : "fa fa-play",
              bgColor : "bg-primary ",
              descripton : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla quasi magni placeat."
            },
            {
              title : "Lorem Ipsum",
              icon : "fa fa-tree",
              bgColor : "bg-warning",
              descripton : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla quasi magni placeat."
            },
            {
              title : "Lorem Ipsum",
              icon : "fa fa-cart-plus",
              bgColor : "bg-info",
              descripton : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla quasi magni placeat."
            },
            {
              title : "Lorem Ipsum",
              icon : "fa fa-cart-plus",
              bgColor : "bg-success",
              descripton : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nulla quasi magni placeat."
            }
        ],
        testimonials : [
          {
              writer  : "Md. Ziaur Rahman",
              link    : "http://192.168.0.120:8080",
              descripton : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi debitis expedita, voluptatum provident dolorum iure!"
          },{
              writer  : "Shak Ziaur Rahman Tito",
              link    : "http://192.168.0.120:8080",
              descripton : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eligendi debitis expedita, voluptatum provident dolorum iure!"
          }
        ]
    }
  },
  created(){
    document.title = "About Us";
  }
}
</script>

<style lang="css" scoped>
</style>