<template>
	<div style="background: #ddd;">
	<section class="my-5 p-3 py-5" style="max-width: 450px;margin: auto;">
      <div class="card">
        <div class="card-body">
          <form class="py-4" @submit.prevent="signup" autocomplete="off">
            <hr>

            <h6 v-if="$store?.state?.errors?.inactive" class="text-center text-danger" id="msg">{{$store?.state?.errors?.inactive}}</h6>
            <h6 v-if="$store?.state?.errors?.network_error" class="text-center text-danger" id="msg">{{$store?.state?.errors?.network_error}}</h6>
            <h6 v-if="$store?.state?.errors?.notMatch" class="text-center text-danger" id="msg">{{$store?.state?.errors?.notMatch}}</h6>
            
            <div class="form-group">
              <label for="phone">Email</label>
              <input type="text" v-model="myData.email" class="form-control" placeholder="E-mail address">
              <small v-if="$store?.state?.errors?.email" class="form-text" style="color:red;">{{$store.state.errors.email[0]}}</small>
            </div>

            <div class="form-group">
              <label  for="password">Password</label>
              <input type="password" v-model="myData.password" class="form-control" placeholder="Password">
              <small v-if="$store?.state?.errors?.password" class="form-text" style="color:red;">{{$store.state.errors.password[0]}}</small>
            </div>

            <div class="form-check my-2">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" name="chbox">
              <label class="form-check-label" for="exampleCheck1">Check me login</label>
            </div>
            <TheButton :block="true" :disabled="$store?.state?.loadding" :loading="$store.state.loadding" :color="'dark'"></TheButton>
          </form>
            <small class="d-block text-center">&copy; {{new Date().getFullYear()}}, {{$store?.state?.siteData?.title}}</small>
            <a class="d-block text-center text-dark pt-2" href="https://www.enrichitsolutions.com">Develop by Enrich IT Solutions</a>

            

        </div>
      </div>
    </section>
  </div>
</template>
<script>

import TheButton from "../../helper/TheButton.vue";

export default {

  name: 'LoginPage',
  data () {
		return {
			myData : {
				email : '',
				password : ''
			}
		}
  },
	mounted(){
	// console.log(this.$store)
	},
  methods : {
		signup(){
			this.$store.dispatch("login/signup", this.myData);
		}
  },
	created(){
		window.document.title = "Login Panel"
	},
	components : {
		TheButton
	}


}
</script>

<style lang="css" scoped>
	body{background: #ddd;}
		input[type="text"], input[type="password"]{
		border: none;
		border-bottom: 1.5px solid #e2e5e8;
		border-radius: 0;
	}
	.form-control:focus {
		color: none;
		background-color: none;
		border-color: none;
		outline: none;
		box-shadow: none;
	}
</style>