import { createRouter, createWebHistory } from 'vue-router';

//Login & registration pages
import login from './pages/admin/LoginPage.vue';
import register from './pages/admin/RegisterPage.vue';


//Admin Pages
import LayoutPage from './pages/admin/inc/LayoutPage.vue';
import DashboardPage from './pages/admin/DashboardPage.vue';
import CategoryPage from './pages/admin/CategoryPage.vue';
import UserlistPage from './pages/admin/UserlistPage.vue';
import UniversityPage from './pages/admin/UniversityPage.vue';
import DocumentPage from './pages/admin/DocumentPage.vue';
import ExamPage from './pages/admin/ExamPage.vue';
import AdminList from './pages/admin/AdminList.vue';
import AgencyList from './pages/admin/AgencyList.vue';
import StudentList from './pages/admin/StudentList.vue';
import AdminProfile from './pages/admin/AdminProfile.vue';
import NavList from './pages/admin/NavList.vue';
import SliderPage from './pages/admin/SliderPage.vue';
import SatisfiedStudent from './pages/admin/SatisfiedStudent.vue';
import PostPage from './pages/admin/PostPage.vue';
import CardPage from './pages/admin/CardPage.vue';
import SetupPage from './pages/admin/SetupPage.vue';
import NewsLetter from './pages/admin/NewsLetter.vue';
import FeedbackPage from './pages/admin/FeedbackPage.vue';
import PageList from './pages/admin/PageList.vue';
import PageCreate from './pages/admin/PageCreate.vue';
import PageUpdate from './pages/admin/PageUpdate.vue';

// Landing Pages 
import HomePage from './pages/frontend/HomePage.vue';
import AboutPage from './pages/frontend/AboutPage.vue';
import LayoutHomePage from './pages/frontend/inc/LayoutPage.vue';
import ContactPage from './pages/frontend/ContactPage.vue';
import BlogPage from './pages/frontend/BlogPage.vue';
import PostDetails from './pages/frontend/PostDetails.vue';
import PageView from './pages/frontend/PageView.vue';


// Agency Pages 
import AgencyDashboard from './pages/agency/DashboardPage.vue';

// Student Pages 
import StudentDashboard from './pages/student/DashboardPage.vue';


const routes = [
	{path: "/login", name : 'login', component : login, meta : { auth : false}},
	{path: "/register", name : 'register', component : register, meta : { auth : false}},
	{
		path : "/admin",
		component : LayoutPage,
		redirect : '/admin',
		children : [
			{path: "/admin", name : 'dashboard', component : DashboardPage, meta : { auth : true, roles : ['admin']}},
			{path: "users", name : 'users', component : UserlistPage, meta : { auth : true , roles : ['admin']}},
			{path: "category", name : 'category', component : CategoryPage, meta : { auth : true ,roles : ['admin']}},
			{path: "university", name : 'university', component : UniversityPage, meta : { auth : true ,roles : ['admin']}},
			{path: "documents", name : 'documents', component : DocumentPage, meta : { auth : true,roles : ['admin']}},
			{path: "exam", name : 'exam', component : ExamPage, meta : { auth : true ,roles : ['admin']}},
			{path: "adminlist", name : 'adminlist', component : AdminList, meta : { auth : true ,roles : ['admin']}},
			{path: "agencylist", name : 'agencylist', component : AgencyList, meta : { auth : true ,roles : ['admin']}},
			{path: "studentlist", name : 'studentlist', component : StudentList, meta : { auth : true ,roles : ['admin']}},
			{path: "profile", name : 'AdminProfile', component : AdminProfile, meta : { auth : true ,roles : ['admin']}},
			{path: "navlist", name : 'NavList', component : NavList, meta : { auth : true ,roles : ['admin']}},
			{path: "slider", name : 'SliderPage', component : SliderPage, meta : { auth : true ,roles : ['admin']}},
			{path: "satisfied", name : 'SatisfiedStudent', component : SatisfiedStudent, meta : { auth : true ,roles : ['admin']}},
			{path: "posts", name : 'PostPage', component : PostPage, meta : { auth : true ,roles : ['admin']}},
			{path: "cards", name : 'CardPage', component : CardPage, meta : { auth : true ,roles : ['admin']}},
			{path: "setup", name : 'SetupPage', component : SetupPage, meta : { auth : true ,roles : ['admin']}},
			{path: "newsLetters", name : 'NewsLetter', component : NewsLetter, meta : { auth : true ,roles : ['admin']}},
			{path: "feedback", name : 'FeedbackPage', component : FeedbackPage, meta : { auth : true ,roles : ['admin']}},
			{path: "pagelist", name : 'PageList', component : PageList, meta : { auth : true ,roles : ['admin']}},
			{path: "pagecreate", name : 'PageCreate', component : PageCreate, meta : { auth : true ,roles : ['admin']}},
			{path: "pquery/:slug", name : 'PageUpdate', component : PageUpdate, meta : { auth : true ,roles : ['admin']}},
		]
	},
	{
		path : "/agency",
		name : 'Agency',
		component : AgencyDashboard,
		meta : { auth : true , roles : ['agency']}
	},
	{
		path : "/student",
		name : 'Student',
		component : StudentDashboard,
		meta : { auth : true , roles : ['student']}
	},
	{
		path : "/",
		component : LayoutHomePage,
		redirect : '/',
		children : [
			{path: "/", name : 'landing', component : HomePage},
			{path: "about", name : 'about', component : AboutPage},
			{path: "contact", name : 'ContactPage', component : ContactPage},
			{path: "blogs", name : 'BlogPage', component : BlogPage},
			{path: "details/:slug", name : 'PostDetails', component : PostDetails},
			{path: "p/:slug", name : 'PageView', component : PageView}
		]
	}
	
];

const router = createRouter({
	routes,
	history : createWebHistory(),
	scrollBehavior(){
		return {
			top : 0
		}
	}
});

router.beforeEach((to, form, next) => {

let token 		= localStorage.getItem('accessToken');
let user_type 	= [localStorage.getItem('user_type')];

	if(to.meta.auth) {
		if (!token) {
			next('/login')
		} else if (!user_type.some(role => to.meta.roles.includes(role))) {
			if(user_type == "admin"){
				next({name : 'dashboard'});
			}else if(user_type == "agency"){
				next({name : 'Agency'});
			}else if(user_type == "student"){
				next({name : 'Student'});
			}
		} else {
			next()
		}
	}else if('auth' in to.meta && !to.meta.auth && token) {
		if(user_type == "admin"){
			next({name : 'dashboard'});
		}else if(user_type == "agency"){
			next({name : 'Agency'});
		}else if(user_type == "student"){
			next({name : 'Student'});
		}
	}else {
		next()
	}

});


export default router;