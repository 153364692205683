import { createApp} from "vue";
import App from "./App.vue";
import router from "./router.js";
import toastr from "toastr"
import store from "./store";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";

import CKEditor from '@ckeditor/ckeditor5-vue';

const app = createApp(App);
app.use(router);

app.config.globalProperties.toastr = toastr;

app.use(store);
app.use(CKEditor);
app.mount("#app");
