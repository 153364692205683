<template>
  <div class="col-md-10">
        <div class="m-2 my-4">
            <div style="overflow: hidden;">
              <h3 class="text-center my-5 py-5">Welcome to admin panel</h3>
            </div>
        </div>
  </div>
</template>

<script>
export default {

  name: 'DashboardPage',

  data () {
    return {

    }
  },
  created(){
    document.title = "Dashboard"
  }
}
</script>

<style lang="css" scoped>
</style>