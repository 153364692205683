<template>
  <div class="col-md-10">
        <div class="m-2 my-4">
          <div class="card my-2">
            <div class="card-body">
            <div style="overflow: hidden;">
              <h3 class="float-left">Category List</h3>
              <h3 class="float-right">
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#insertModal">Add New</button>
              </h3>
            </div>
            <hr>

            </div>
          </div>
        </div>
  </div>



<!-- Insert modal -->
<div class="modal fade" id="insertModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Add Data</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
      <form enctype="multipart/form-data" @submit.prevent="insert">

        <div class="row" v-for="(item, id) in insertData" :key="id">
          <div class="col-md-3 my-2">
            <input type="text" name="name" class="form-control" v-model="item.name" placeholder="University name" required>
          </div>

          <div class="col-md-3 my-2">
            <input type="text" name="name" class="form-control" v-model="item.address" placeholder="Address" required>
          </div>

          <div class="col-md-3 my-2">
            <input type="file" name="name" class="form-control" @change="image($event, id)">
          </div>

          <div class="col-md-2 my-2">
            <button class="btn btn-danger" type="button" @click="remove(item, id)">Remove</button>
          </div>
        </div>

        <button class="btn btn-success my-2" type="button" @click="addMore()">Add +</button>
        <br>
        <button type="submit" class="btn btn-primary my-2">Save Change</button>
      </form>
      </div>
    </div>
  </div>
</div> 
</template>

<script>


import { onMounted } from 'vue';
// import $ from 'jquery';
import 'bootstrap';

import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import  'datatables.net-bs4';
DataTable.use(DataTablesCore);


// let updateModal = null;
// let insertModal = null;


export default {
  setup(){
    onMounted(()=>{
      // updateModal = $("#updateModal");
      // insertModal = $("#insertModal");
    })
  },
  data() {
      return {
          insertData : [{name : '', image : '', address : ''}],
          myData : "Hello"
      }
  },
  mounted(){
    console.log(this.$store.state);
  },
  methods: {
    image(event, index){
      this.insertData[index].image = event.target.files[0] ? event.target.files[0] : "";
    },
    addMore(){
      this.toastr.success('My name is Inigo Montoya. You killed my father');
      this.insertData.push({name : '', image : '', address : ''});
    },

    remove(remove, index){
      this.insertData.splice(index, 1);
    }
  },
  created(){
    document.title = "Category List"
  }
};
</script>
